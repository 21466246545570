<template>
  <div class="s-main-container s-ptb-40 s-bg-white">
    <h4 class="s-text-text">{{ content.title }}</h4>
    <div class="s-center-flex">
      <p>{{ convertDate(content.date) }}</p>
      <p class="s-mlr-16">|</p>
      <div class="s-center-flex s-mtb-3">
        <div
          class="s-tag-secondary"
          v-for="(i, index) in content.expertise_detail"
          :key="`tag-${index}`"
        >
          {{ i.name }}
        </div>
      </div>
    </div>
    <div>
      <h6 class="s-text-italic">
        {{ $t("contentDetail.author") }}: {{ content.created_by }}
      </h6>
    </div>
    <div class="s-fullWidth s-ht-500 s-overflow-hidden s-fullcenter-flex">
      <img
        :id="content.slug"
        :class="`${isMobile ? 's-fullWidth' : 's-ht-500'} r-radius-10`"
        :src="content.thumbnail"
      />
    </div>

    <div class="s-text-justify">
      <p>{{ content.description }}</p>
    </div>
    <div class="s-mt-30" v-if="content.youtube_link">
      <iframe
        :src="`https://www.youtube.com/embed/${content.youtube_link}`"
        style="display: block"
        :class="`s-ml-auto s-mr-auto ${
          !isMobile ? 's-wd-70-p s-ht-500' : 's-fullWidth s-ht-200'
        }`"
      ></iframe>
    </div>
    <div v-if="files.length > 0">
      <div class="s-mt-60">
        <div>
          <h5>{{ $t("contentDetail.detailDocument") }}</h5>
        </div>
        <div
          class="s-fullWidth s-bg-green-40 s-radius-10 s-mtb-16"
          v-for="(i, index) in content.files"
          :key="`file-${index}`"
        >
          <div
            v-if="i.mime_type == 'application/pdf'"
            class="s-center-flex s-wrap s-plr-16 s-ptb-5"
          >
            <div :class="`${isMobile ? '' : 's-wd-p-75'}`">
              <tr>
                <td>
                  <p>{{ $t("contentDetail.documentTitle") }}</p>
                </td>
                <td class="s-plr-10"><p>:</p></td>
                <td>
                  <p>{{ i.filename }}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{{ $t("contentDetail.documentType") }}</p>
                </td>
                <td class="s-plr-10"><p>:</p></td>
                <td>
                  <p>{{ i.mime_type }}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{{ $t("contentDetail.documentSize") }}</p>
                </td>
                <td class="s-plr-10"><p>:</p></td>
                <td>
                  <p>{{ i.file_size }} KB</p>
                </td>
              </tr>
            </div>
            <div class="s-ml-auto s-mr-16">
              <Button @click.prevent="downloadFile(i.file_path, i.mime_type)">
                {{ $t("contentDetail.openDocument") }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/helper/Button";
import { convertDate } from "@/common/timeformat";

export default {
  computed: {
    ...mapGetters({
      content: "content/getContentBrrd",
      isMobile: "getIsMobile",
    }),
    files() {
      return this.content.files || [];
    },
  },
  data: () => ({
    loading: false,
  }),
  components: {
    Button,
  },
  methods: {
    ...mapActions({
      setContent: "content/setSinglePublicData",
    }),
    async initData() {
      await this.setContent(this.$route.params.slug);
    },
    downloadFile(filepath, label) {
      this.loading = true;
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_API + "/public/file/" + filepath;
      link.target = "_blank";
      link.download = label;
      link.dispatchEvent(new MouseEvent("click"));
      this.loading = false;
    },
    convertDate(value) {
      return convertDate(value);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>